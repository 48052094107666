import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalHeaderProps,
  ModalFooterProps,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import {
  CommonEventProps,
  customEvent,
  EventProps,
  LARGEL_BUTTON_TYPE,
} from "goi_common";
import React, { ReactElement, ReactNode } from "react";
import {
  OverlayModalFooterOneBtnType,
  OverlayModalFooterTwinBtnType,
} from "./OverlayModalFooter";
import {
  OverlayModalHeaderCloseBtnType,
  OverlayModalHeaderCloseLargeBtnType,
} from "./OverlayModalHeader";

export interface FooterItemProps {
  text: ReactNode;
  onClick: () => void;
  width?: string;
  disable?: boolean;
  event?: EventProps;
  background?: string;
  oneBtnType?: LARGEL_BUTTON_TYPE;
}

export interface HeaderItemProps {
  title?: string;
  onClick?: () => void;
  closeEvent: {
    newGtm: CommonEventProps;
  };
}

export interface OverlayModalFooterProps {
  item: FooterItemProps[];
}

export interface OverlayModalHeaderProps {
  item: HeaderItemProps;
}

export interface OverlayModalProps {
  body: ReactElement;
  headerProps: OverlayModalHeaderProps;
  footerProps: OverlayModalFooterProps;
}

export function OverlayModal({
  isOpen,
  onClose,
  closeEvent,
  header,
  overlayModalContent,
  footer,
  modalWrapProps,
  modalContentStyle,
  closeOnOverlayClick,
}: {
  isOpen: boolean;
  onClose: () => void;
  closeEvent?: EventProps;
  header?: { content: ReactElement; style?: ModalHeaderProps };
  overlayModalContent: ReactElement;
  footer?: { content: ReactElement; style?: ModalFooterProps };
  modalWrapProps?: Omit<ModalProps, "children" | "isOpen" | "onClose">;
  modalContentStyle?: ModalContentProps;
  closeOnOverlayClick?: boolean;
}) {
  return (
    <Modal
      {...modalWrapProps}
      isOpen={isOpen}
      onClose={() => {
        if (closeEvent) customEvent(closeEvent);
        onClose();
      }}
      closeOnOverlayClick={closeOnOverlayClick || false}
      scrollBehavior="inside"
      isCentered={true}
      autoFocus={false}
      closeOnEsc={false}
      returnFocusOnClose={false}
    >
      <ModalOverlay width="100%" height="100%" />
      <ModalContent {...modalContentStyle} width="100%" maxHeight="80%">
        {header && (
          <ModalHeader
            {...header.style}
            padding={header.style?.padding || "0px"}
          >
            {header.content}
          </ModalHeader>
        )}
        <ModalBody padding="0px" borderRadius="12px">
          {overlayModalContent}
        </ModalBody>
        {footer && (
          <ModalFooter
            css={css`
              footer {
                min-height: 48px;
              }
            `}
            {...footer.style}
            borderRadius={footer.style?.borderRadius || "0px 0px 5px 5px"}
            overflow="hidden"
            minHeight="48px"
            padding={footer.style?.padding || 0}
          >
            {footer.content}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

OverlayModal.Footer = {
  OneBtnType: OverlayModalFooterOneBtnType,
  TwinBtnType: OverlayModalFooterTwinBtnType,
};

OverlayModal.Header = {
  CloseBtnType: OverlayModalHeaderCloseBtnType,
  CloseLargeBtnType: OverlayModalHeaderCloseLargeBtnType,
};
